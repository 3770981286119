import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import AboutUsLayout from '../../components/AboutUsLayout';
import SEO from '../../components/SEO/SEO';
import { withPrefix } from 'gatsby';

import './about-page.scss';

const News = injectIntl(({ intl, location }) => {
  return (
    <AboutUsLayout intl={intl} location={location} pageName="about-us-page">
      <section className="press-center-wrapper">
        <div>
          <section className="press-center-content">
            <header>
              <h2 className="press-center-title">
                <FormattedMessage id="pages.about.press-center.title" />
              </h2>
            </header>
            <div className="press-center-contact">
              <p>
                <FormattedMessage id="pages.about.press-center.text" />
              </p>
              <div className="press-center-inquiries-contact">
                <p>
                  <strong>Salina Shairulla</strong>
                  <a href="mailto:salina.shairulla@capitalone.com">salina.shairulla@capitalone.com</a>
                </p>
                <p>
                  <strong>Michelle Reidel</strong>
                  <a href="mailto:michelle.reidel@capitalone.com">michelle.reidel@capitalone.com</a>
                </p>
              </div>
            </div>
            {Array(26)
              .fill()
              .map((_, i) => {
                const details = intl.formatMessage({ id: `pages.about.press-center.links.details${i + 1}` });
                return (
                  <div key={i} className="press-center-news">
                    <h4>
                      <FormattedMessage id={`pages.about.press-center.links.date${i + 1}`} />
                    </h4>
                    <h3>
                      <a
                        href={withPrefix(intl.formatMessage({ id: `pages.about.press-center.links.link${i + 1}` }))}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedMessage id={`pages.about.press-center.links.linkText${i + 1}`} />
                      </a>
                    </h3>
                    {details !== 'n/a' && <p className="sub-text">{details}</p>}
                  </div>
                );
              })
              .reverse()}
          </section>
        </div>
      </section>
    </AboutUsLayout>
  );
});

export default News;

/* eslint-disable react/prop-types */
export const Head = ({ pageContext }) => (
  <SEO
    titleKey="pages.about.press-center.page-title"
    descriptionKey="pages.about.press-center.page-desc"
    intl={pageContext.intl}
  />
);
/* eslint-enable react/prop-types */
